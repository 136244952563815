import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import Dialog from './elements/Dialog'
import IndividualRegistrationForm from './forms/IndividualRegistrationForm'
import RepeaterForm from './forms/RepeaterForm'
import PageTitle from './Page/Title'
import PaymongoCheckout from './PaymongoCheckout'
import { Event as EventApi, Participant as ParticipantApi } from '../API'
import { checkPastParticipant } from '../constants/pastParticipants'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
// import ShoppingCart from './ShoppingCart'

const STEP_REPEATER = 0
const STEP_PARTICIPANT = 1
const STEP_CHECKOUT = 2

const steps = ['Participant details', 'Checkout']

type Event = Omit<Exclude<EventApi, null>, '__typename'>
type Participant = Omit<Exclude<ParticipantApi, null>, '__typename'>

const SnackbarComponent = ({
  title,
  message,
}: {
  title: string
  message: string
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2">{message}</Typography>
    </div>
  )
}

interface Repeater {
  show: boolean
  slug: string | null
}
interface Props {
  event: Event
  categoryId: string
  repeater: Repeater
}

const IndividualRegistration: React.FC<Props> = ({
  event,
  categoryId,
  repeater,
}) => {
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const displayError = ({
    title,
    message,
  }: {
    title: string
    message: string
  }) => {
    enqueueSnackbar(<SnackbarComponent title={title} message={message} />, {
      variant: 'error',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      autoHideDuration: 10000,
      onClose: (event, reason, key) => {
        if (reason === 'instructed') {
          closeSnackbar(key)
        }
      },
    })
  }
  const [activeStep, setActiveStep] = useState(
    repeater.show ? STEP_REPEATER : STEP_PARTICIPANT,
  )
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const {
    startedAt,
    cartUser,
    addUser,
    addProduct,
    updateProduct,
    cartItems,
    clearCart,
  } = useContext(CartContext)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const [categories, setCategories] = useState([])
  const [participant, setParticipant] = useState<Participant>()

  const [showSize, setShowSize] = useState(false)
  const [showBikeSize, setShowBikeSize] = useState(false)
  const [showSizeImage, setShowSizeImage] = useState('')

  const processStartedAt = useCallback(() => {
    clearCart()
  }, [clearCart])

  useEffect(() => {
    if (
      (startedAt === null && Object.keys(cartUser).length > 0) ||
      (startedAt !== null && dayjs().diff(dayjs(startedAt), 'm', true) >= 15)
    )
      processStartedAt()
  }, [startedAt, cartUser, processStartedAt])

  useEffect(() => {
    if (event?.categories?.items) {
      setCategories(
        event?.categories?.items?.map((cat) => ({
          id: cat.id,
          name: cat.name,
          fee: cat.fee,
          adminFee: cat.adminFee,
          priority: cat.priority,
          disabled:
            typeof cat.maxParticipants === 'number' &&
            cat.categoryCount['count'] >= cat.maxParticipants,
          isTandem: cat.isTandem || false,
          tandemAgeRange: `${cat.tandemAgeRange}`,
          eventEndDateTime: event.endDateTime,
          isTriathlon: cat.isTriathlon || false,
          bonusParticipants: cat.bonusParticipants || 0,
          repeaterSlug: cat.repeaterSlug,
          repeaterUntil: cat.repeaterUntil,
          hasJersey: cat.hasJersey || false,
        })),
      )
    }
  }, [event])

  const updateParticipantList = useCallback(() => {
    if (cartItems.length > 0) setParticipant(cartItems[0])
  }, [cartItems])

  useEffect(() => {
    updateParticipantList()
  }, [cartItems, updateParticipantList])

  const repeaterContent = () => {
    return (
      <RepeaterForm
        user={{
          ...cartUser,
        }}
        editing={!!cartUser}
        setLoading={setPageLoading}
        displayError={displayError}
        updateData={(data) => {
          const validRepeater = checkPastParticipant(
            repeater.slug,
            data.bibNumber,
            data.firstName,
            data.lastName,
          )
          if (validRepeater) {
            addUser({
              firstName: data.firstName.trim(),
              lastName: data.lastName.trim(),
            })

            handleNext()
          } else
            return [
              null,
              {
                title:
                  'Sorry. Only repeater (finisher) participants allowed at this stage.',
                message:
                  'Please ensure you enter the correct information, or contact support@megatechph.com for assistance',
              },
            ]
        }}
        onCancel={() => {
          clearCart()
          navigate(-1)
        }}
      />
    )
  }

  const userDetailsContent = useCallback(() => {
    return (
      <>
        <div
          style={{ margin: 'auto', textAlign: 'center', marginBottom: '10px' }}>
          <Button
            color="primary"
            onClick={() => {
              setShowSizeImage('size_chart_v2.jpg')
              setShowSize(true)
              setShowBikeSize(false)
            }}>
            View T-Shirt Size Chart
          </Button>
          {categories.filter((cat) => cat.id === categoryId)[0]?.hasJersey && (
            <Button
              color="primary"
              onClick={() => {
                setShowSizeImage(event.jerseySizeChart ?? 'size_chart_v2.jpg')
                setShowSize(true)
                setShowBikeSize(false)
              }}>
              View Bike Jersey Size Chart
            </Button>
          )}
        </div>
        <div style={{ height: 30 }} />

        <IndividualRegistrationForm
          user={{
            categoryId,
            nationality: 'PH',
            ...cartUser,
            ...(participant && {
              shirtSize: participant.shirtSize,
            }),
            hasJersey: categories.filter((cat) => cat.id === categoryId)[0]
              ?.hasJersey,
          }}
          showRepeater={repeater.show}
          editing={!!cartUser}
          setLoading={setPageLoading}
          updateData={(data) => {
            addUser({
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber,
            })

            const fee = categories.filter((cat) => cat.id === categoryId)[0].fee
            const adminFee = categories.filter(
              (cat) => cat.id === categoryId,
            )[0].adminFee

            const product = {
              ...participant,
              firstName: data.firstName,
              lastName: data.lastName,
              shirtSize: data.shirtSize,
              jerseySize: `${data.jerseySize || ''}`,
              bibName: `${data.bibName || ''}`,
              teamName: `${data.teamName || ''}`,
              birthdate: `${data.birthdate}`,
              nationality: data.nationality,
              gender: `${data.gender}`,
              address: `${data.address}`,
              phoneNumber: `${data.phoneNumber}`,
              emergencyContactName: data.emergencyContactName,
              emergencyContactPhone: data.emergencyContactPhone,
              eventId: event.id,
              eventName: `${event.isVirtual ? 'VIRTUAL - ' : ''}${event.name}`,
              categoryId,
              categoryName: categories.filter((cat) => cat.id === categoryId)[0]
                ?.name,
              fee,
              adminFee,
            }

            if (cartItems.length > 0) {
              updateProduct(product)
            } else {
              addProduct({
                ...product,
                id: uuidv4(),
              })
            }

            setPageLoading(false)
            handleNext()
          }}
          onCancel={() => {
            if (repeater.show) {
              handleBack()
            } else {
              clearCart()
              navigate(-1)
            }
          }}
        />
      </>
    )
  }, [categories, categoryId, cartUser])

  const checkoutContent = () => {
    return (
      <PaymongoCheckout
        onCancel={handleBack}
        isVirtualEvent={!!event?.isVirtual}
        isFree={'isFree' in event && event.isFree === 1}
      />
    )
  }

  const stepProps: { completed?: boolean } = {}

  return (
    <>
      <PageTitle
        title={`Register to ${
          event?.isVirtual ? 'VIRTUAL - ' : ''
        }${event?.name}`}
      />
      <div style={{ height: 48 }} />
      <Stepper activeStep={activeStep}>
        {repeater.show && (
          <Step key={'Repeater'} {...stepProps}>
            <StepLabel>Repeater</StepLabel>
          </Step>
        )}
        {steps.map((label) => {
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <div style={{ height: 32 }} />

      <React.Fragment>
        {repeater.show && activeStep === STEP_REPEATER && repeaterContent()}
        {activeStep === STEP_PARTICIPANT && userDetailsContent()}
        {activeStep === STEP_CHECKOUT && checkoutContent()}
        <Dialog
          open={showSize || showBikeSize}
          maxWidth={'lg'}
          onClose={() => {
            setShowSize(false)
            setShowBikeSize(false)
          }}
          content={
            <Grid
              item
              sx={(theme) => ({
                // height: '100vh',
                // overflow: 'scroll',
                width: '100vw',
                [theme.breakpoints.up('md')]: {
                  // overflow: 'hidden',
                  width: 'calc(100vw/2)',
                  height: 'calc(100vh/2)',
                },
              })}>
              <Box
                sx={(theme) => ({
                  height: '100%',
                  overflow: 'scroll',
                  [theme.breakpoints.up('md')]: {
                    overflow: 'unset',
                  },
                })}>
                <CardMedia
                  sx={(theme) => ({
                    height: '650px',
                    // backgroundSize: 'cover',
                    backgroundSize: 'contain',
                    backgroundPosition: 'left center',
                    width: '920px',
                    [theme.breakpoints.up('md')]: {
                      height: '100%',
                      width: 'unset',
                      backgroundPosition: 'center',
                      // backgroundSize: 'contain',
                    },
                  })}
                  image={`${process.env.PUBLIC_URL}/images/${showSizeImage}`}
                />
              </Box>
            </Grid>
          }
          buttons={
            <>
              <Button
                variant="text"
                onClick={() => {
                  setShowSizeImage('')
                  setShowSize(false)
                  setShowBikeSize(false)
                }}>
                Close
              </Button>
            </>
          }
        />
      </React.Fragment>
    </>
  )
}
export default IndividualRegistration
